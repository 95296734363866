import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { BranchService } from 'src/app/services/Branch.Service';
import { BrandService } from 'src/app/services/Brand.Service';


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  @Output() closeSide = new EventEmitter();
  businessId: any;
  lang: any;
  hasLoyaltyRole: boolean;
  hasAnalyticsRole: boolean;
  hasFreemiumRole: boolean;
  brandId: any;
  constructor(
    private cookie: CookieService,
    private service2: BranchService,
    private brandService: BrandService,
    public translate: TranslateService
  ) { }

  items = [];

  async ngOnInit() {
    this.lang = this.cookie.get('lang');
    this.translate.onLangChange.subscribe((event: any) => {
      this.lang = event.lang;
    });
    const result: any = await this.brandService.viewSubscriptionPackages().toPromise();
    this.hasLoyaltyRole = result.data.subscriptionPackages.includes('loyalty');
    this.hasAnalyticsRole = result.data.subscriptionPackages.includes('analytics');
    this.hasFreemiumRole = result.data.subscriptionPackages.includes('freemium');
    this.buildMenu();
    const user = JSON.parse(localStorage.getItem('User'));
    this.service2.getBusinessData(user.id).subscribe(res => {
      const bs = res.body.data;
      this.brandId = bs.brand._id;
      // check if brandId Archi
      if (this.brandId === '63af0d2edb097543faec3964') {
        this.items.splice(1, 0, {
          isParent: 'n',
          name: 'Utilization',
          icon: 'monitoring',
          route: '/utilization'
        });
      }
    });
  }
  buildMenu() {
    this.items = [
      {
        isParent: 'n',
        name: 'Dashboard',
        icon: 'trending_up',
        route: '/',
        disabled: !this.hasAnalyticsRole
      },
      {
        isParent: 'n',
        name: 'Brand',
        icon: 'stars',
        route: '/brands'
      },
      {
        isParent: 'n',
        name: 'Branches',
        icon: 'store',
        route: '/branches'
      },
      {
        isParent: 'n',
        name: 'Cashiers',
        icon: 'person',
        route: '/cashiers'
      },
      {
        isParent: 'n',
        name: 'Sync Foodics',
        icon: 'sync',
        route: '/sync-foodics'
      },
      {
        isParent: 'n',
        name: 'Sync Marn',
        icon: 'sync',
        route: '/sync-marn'
      },
      {
        isParent: 'n',
        name: 'Sync Retm',
        icon: 'sync',
        route: '/sync-retm'
      },
      {
        isParent: 'n',
        name: 'Sync Odoo',
        icon: 'sync',
        route: '/sync-odoo'
      },
      {
        isParent: 'n',
        name: 'Sync Deliverect',
        icon: 'sync',
        route: '/sync-deliverect'
      },
      {
        isParent: 'n',
        name: 'Gift card',
        icon: 'card_giftcard',
        route: '/gift-card'
      },
      {
        isParent: 'n',
        name: 'Payouts',
        icon: 'attach_money',
        route: '/payouts'
      },
      {
        isParent: 'n',
        name: 'Generate Payment',
        icon: 'paid',
        route: '/payment-invoices'
      },
      {
        isParent: 'y',
        name: 'Products',
        children: [
          {
            name: 'Product Category',
            icon: 'toc',
            route: '/product_category'
          },
          {
            isParent: 'n',
            name: 'Products',
            icon: 'fastfood',
            route: '/products'
          }
        ]
      },
      {
        isParent: 'y',
        name: 'Loyalty',
        children: [
          {
            isParent: 'n',
            name: 'Points Rules',
            icon: 'control_point',
            route: '/points_rules',
            disabled: !this.hasLoyaltyRole && !this.hasFreemiumRole
          },
          {
            isParent: 'n',
            name: 'Points Rewards',
            icon: 'card_giftcard',
            route: '/rewards',
            disabled: !this.hasLoyaltyRole && !this.hasFreemiumRole
          },
          {
            isParent: 'n',
            name: 'Stamp Cards',
            icon: 'local_offer',
            route: '/stamps',
            disabled: !this.hasLoyaltyRole && !this.hasFreemiumRole
          },
          {
            isParent: 'n',
            name: 'Wallet Notification',
            icon: 'wallet',
            route: '/apple-wallet/add',
            disabled: !this.hasLoyaltyRole
          }
        ]
      },
      {
        isParent: 'y',
        name: 'Reports',
        children: [
          {
            name: 'Orders',
            icon: 'shopping_cart',
            route: '/orders',
            disabled: !this.hasAnalyticsRole && !this.hasLoyaltyRole && !this.hasFreemiumRole
          },
          {
            name: 'Customers',
            icon: 'people', // store
            route: '/customers',
            disabled: !this.hasAnalyticsRole && !this.hasLoyaltyRole && !this.hasFreemiumRole
          },

          {
            name: 'Vouchers',
            icon: 'receipt',
            route: '/reports/vouchers',
            disabled: !this.hasAnalyticsRole
          },
          {
            name: 'Franchise',
            icon: 'storefront',
            route: '/franchise',
            disabled: !this.hasAnalyticsRole
          },
          {
            name: 'Orders By Nugttah',
            icon: 'add_shopping_cart',
            route: '/pickup-transactions'
          }, {
            isParent: 'n',
            name: 'Tables Orders',
            icon: 'table_chart',
            route: '/tableorders'
          },

          {
            isParent: 'n',
            name: 'SMS',
            icon: 'sms',
            route: '/smslist'
          }
        ]
      }
    ];
  }
  closeMenu() {
    this.closeSide.emit();
  }
}