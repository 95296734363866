import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { Error404Component } from './pages/error404/error404.component';
import { ForgetPasswordComponent } from './pages/login/forget-password/forget-password.component';
import { AdminAuthGuard } from './services/Gaurds/AdminAuth.Gaurd';
import { NonAuthGuard } from './services/Gaurds/NonAdminAuth.Gaurd';
import { AnalyticsAuthGuard } from './services/Gaurds/AnalyticsAuth.Guard';
import { LoyaltyAuthGuard } from './services/Gaurds/LoyaltyAuth.Guard';
import { FreemiumAuthGuard } from './services/Gaurds/FreemiumAuth.Guard';
import { AnyPackageAuthGuard } from './services/Gaurds/AnyPackageAuth.Guard';
import { LoyaltyOrFreemiumAuthGuard } from './services/Gaurds/LoyaltyOrFreemiumAuth.Guard';
import { ArchiAuthGuard } from './services/Gaurds/ArchiAuthGuard';


const routes: Routes = [
  {
    path: '',
    canActivate: [AdminAuthGuard, AnalyticsAuthGuard],
    component: DashboardComponent
  },
  {
    path: 'dashboard',
    canActivate: [AdminAuthGuard, AnalyticsAuthGuard],
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'brands',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule),
    data: {
      breadcrumb: 'Brand'
    }
  },
  {
    path: 'birthday-offer',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./pages/birthday-offer/birthday-offer.module').then(m => m.BirthdayOfferModule),
    data: {
      breadcrumb: 'Birthday Offer'
    }
  },
  {
    path: 'first-visit-voucher',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./pages/first-visit-voucher/first-visit-voucher.module').then(m => m.FirstVisitVoucherModule),
    data: {
      breadcrumb: 'First visit voucher'
    }
  },
  {
    path: 'branches',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./pages/branches/branches.module').then(m => m.BranchesModule),
    data: {
      breadcrumb: 'Branches'
    }
  },
  {
    path: 'cashiers',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./pages/cashiers/cashiers.module').then(m => m.CashiersModule),
    data: {
      breadcrumb: 'Cashiers'
    }
  },
  {
    path: 'gift-card',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./pages/gift-cards/gift-cards.module').then(m => m.GiftCardsModule),
    data: {
      breadcrumb: 'Gift card'
    }
  },
  {
    path: 'tableorders',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./pages/tables-orders/tables-orders.module').then(m => m.TablesOrdersModule),
    data: {
      breadcrumb: 'Table Orders'
    }
  },
  {
    path: 'smslist',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./pages/sms-list/sms-list.module').then(m => m.SmsListModule),
    data: {
      breadcrumb: 'SMS List'
    }
  },
  {
    path: 'franchise',
    canActivate: [AdminAuthGuard, AnalyticsAuthGuard],
    loadChildren: () => import('./pages/franchise/franchise.module').then(m => m.FranchiseModule)
  },
  {
    path: 'product_category',
    canActivate: [AdminAuthGuard],
    loadChildren: () =>
      import('./pages/product_category/product_category.module').then(
        m => m.ProductCategoriesModule
      ),
    data: {
      breadcrumb: 'Product Category'
    }
  },
  {
    path: 'products',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./pages/product/product.module').then(m => m.ProductModule),
    data: {
      breadcrumb: 'Products'
    }
  },
  {
    path: 'orders',
    canActivate: [AdminAuthGuard, AnyPackageAuthGuard],
    loadChildren: () => import('./pages/orders-review/orders-review.module').then(m => m.OrdersReviewModule),
    data: {
      breadcrumb: 'Orders'
    }
  },
  {
    path: 'points_rules',
    canActivate: [AdminAuthGuard, LoyaltyOrFreemiumAuthGuard],
    loadChildren: () =>
      import('./pages/points_rules/points_rules.module').then(m => m.PointsRulesModule),
    data: {
      breadcrumb: 'Points Rules'
    }
  },
  {
    path: 'rewards',
    canActivate: [AdminAuthGuard, LoyaltyOrFreemiumAuthGuard],
    loadChildren: () => import('./pages/reward/reward.module').then(m => m.RewardModule),
    data: {
      breadcrumb: 'Rewards'
    }
  },
  {
    path: 'stamps',
    canActivate: [AdminAuthGuard, LoyaltyOrFreemiumAuthGuard],
    loadChildren: () => import('./pages/stamps/stamps.module').then(m => m.StampsModule),
    data: {
      breadcrumb: 'Stamps'
    }
  },
  {
    path: 'reports',
    canActivate: [AdminAuthGuard, AnalyticsAuthGuard],
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'customers',
    canActivate: [AdminAuthGuard, AnyPackageAuthGuard],
    loadChildren: () => import('./pages/customers-review/customers-review.module').then(m => m.CustomersReviewModule),
    data: {
      breadcrumb: 'Customers Review'
    }
  },
  {
    path: 'pickup-transactions',
    canActivate: [AdminAuthGuard],
    data: {
      breadcrumb: 'Pickup Transactions'
    },
    loadChildren: () =>
      import('./pages/pickup-transactions/pickup-transactions.module').then(m => m.PickupTransactionsModule)
  },
  {
    path: 'sync-foodics',
    canActivate: [AdminAuthGuard],
    data: {
      breadcrumb: 'Sync Foodies'
    },
    loadChildren: () =>
      import('./pages/sync-foodies/sync-foodies.module').then(m => m.SyncFoodiesModule)
  },
  {
    path: 'sync-marn',
    canActivate: [AdminAuthGuard],
    data: {
      breadcrumb: 'Sync Marn'
    },
    loadChildren: () =>
      import('./pages/sync-marn/sync-marn.modules').then(m => m.SyncMarnModule)
  },
  {
    path: 'sync-retm',
    canActivate: [AdminAuthGuard],
    data: {
      breadcrumb: 'Sync Retm'
    },
    loadChildren: () =>
      import('./pages/sync-retm/sync-retm.module').then(m => m.SyncRetmModule)
  },
  {
    path: 'sync-odoo',
    canActivate: [AdminAuthGuard],
    data: {
      breadcrumb: 'Sync odoo'
    },
    loadChildren: () =>
      import('./pages/sync-odoo/sync-odoo.module').then(m => m.SyncOdooModule)
  },
  {
    path: 'sync-deliverect',
    loadChildren: () => import('./pages/sync-deliverect/sync-deliverect.module').then(m => m.SyncDeliverectModule),
    canActivate: [AdminAuthGuard],
    data: {
      breadcrumb: 'Sync Deliverect'
    }
  },
  {
    path: 'apple-wallet',
    canActivate: [AdminAuthGuard, LoyaltyAuthGuard],
    data: {
      breadcrumb: 'Add Wallet Notification'
    },
    loadChildren: () =>
      import('./pages/apple-wallet/apple-wallet.module').then(m => m.AppleWalletModule)
  },
  {
    path: 'redirect',
    loadChildren: () =>
      import('./pages/redirect-from-admin/redirect-from-admin.module').then(m => m.RedirectFromAdminModule)
  },
  {
    path: 'shared-vouchers',
    canActivate: [AdminAuthGuard],
    data: {
      breadcrumb: 'Shard Vouchers'
    },
    loadChildren: () =>
      import('./pages/shared-vouchers/shared-vouchers.module').then(m => m.SharedVouchersModule)
  },
  // profile
  {
    path: 'profile',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.CategoriesModule),
    data: {
      breadcrumb: 'Profile'
    }
  },
  {
    path: 'modifiers',
    canActivate: [AdminAuthGuard],
    loadChildren: () =>
      import('./pages/modifiers/modifiers.module').then(m => m.ModifiersModule),
    data: {
      breadcrumb: 'Modifiers'
    }
  },
  {
    path: 'utilization',
    canActivate: [AdminAuthGuard, ArchiAuthGuard],
    loadChildren: () =>
      import('./pages/utilization/utilization.module').then(m => m.UtilizationModule)
  },
  // mail
  {
    path: 'mail',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./pages/mail/mail.module').then(m => m.MailModule),
    data: {
      breadcrumb: 'Mail'
    }
  },
  {
    path: 'payouts',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./pages/payouts/payouts.module').then(m => m.PayoutsModule),
    data: {
      breadcrumb: 'Payouts'
    }
  }
  ,
  {
    path: 'terms-and-conditions',

    loadChildren: () =>
      import('./pages/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule)
  },

  {
    path: 'pdf',

    loadChildren: () =>
      import('./pages/pdf-report/pdf-report.module').then(m => m.PdfReportModule)
  },

  {
    path: 'verify-account',
    canActivate: [NonAuthGuard],
    loadChildren: () =>
      import('./pages/verify-account/verify-account.module').then(m => m.VerifyAccountModule)
  },




  {
    path: 'login/forget-password',
    canActivate: [NonAuthGuard],
    component: ForgetPasswordComponent
  },
  {
    path: 'login',
    canActivate: [NonAuthGuard],
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'signup',
    canActivate: [NonAuthGuard],
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: 'payment-invoices',
    canActivate: [AdminAuthGuard],
    loadChildren: () => import('./pages/payment-invoices/payment-invoices.module').then(m => m.GeneratePaymentModule),
    data: {
      breadcrumb: 'Generate Payment'
    }
  },
  { path: '**', component: Error404Component }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }