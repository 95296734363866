import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { NpsService } from '../services/nps.service';
import { AdminAuthService } from '../services/AdminAuth.Service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BranchesService } from '../services/branches.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    trigger('fadeOut', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('in => out', [animate('0.5s ease-out')])
    ])
  ]
})
export class LayoutComponent implements OnInit {
  view_header_footer: boolean = false;
  showFiller: boolean = true;
  lang: any;
  showNps: boolean;
  hideBanner = false;
  urlForOldBO: string = '';
  fadeState: string = 'in';
  hideAlert: boolean = false;
  subscriptionBranchesDetails: any;
  branchNames: { en: string; ar: string }[] = [];
  subscriptionEndDate = [];
  isProd: boolean;
  constructor(
    public translate: TranslateService,
    private cookie: CookieService,
    private npsService: NpsService,
    private adminService: AdminAuthService,
    private branchesService: BranchesService
  ) { }

  ngOnInit() {
    this.lang = this.cookie.get('lang');
    this.translate.onLangChange.subscribe((event: any) => {
      this.lang = event.lang;
    });

    const user = JSON.parse(localStorage.getItem('User'));

    if (user) {
      this.view_header_footer = true;
      const token = localStorage.getItem('JWT_TOKEN');
      const adminId = localStorage.getItem('adminId');
      if (token) {
        this.urlForOldBO = `https://test-new.partner.nugttah.com/redirect?log=n&accesstoken=${token}&id=${user.id}&image=${user.image_path}&brand=${user.brand}&adminId=${adminId}`;
      } else {
        console.warn('Token is missing from localStorage');
        this.urlForOldBO = '';
      }
    }

    // if (user != null) {
    //   this.view_header_footer = true;
    // }

    const isAgent = localStorage.getItem('isAgent');
    this.adminService.isAuthenticated().then((res: any) => {
      if (res.IsLoggedIn) {
        this.getSubscriptionBranches();
      }
      if (res.IsLoggedIn && isAgent !== 'true') {
        this.checkShowNps();
      }
    });
    this.isProd = window.location.href.includes('partner.nugttah.com');
    if (!this.isProd) {
      this.hideBanner = true;
    }
  }

  checkShowNps() {
    this.npsService.checkIfPartnerShouldRate().subscribe(
      (response: any) => {
        if (response.data.businessShouldRate) {
          this.showNps = true;
        }
      }
    );
  }

  openSide() {
    this.showFiller = true;
  }

  closeBanner() {
    this.fadeState = 'out';
    const header = document.querySelector('.header-section');
    if (header) {
      if (this.hideAlert) {
        header['style'].position = 'sticky';
        header['style'].top = '0';
        header['style'].left = '0';
        header['style'].width = '100%';
      } else {
        header['style'].position = 'sticky';
        header['style'].top = '50';
        header['style'].left = '0';
        header['style'].width = '100%';
      }
    }
  }

  onAnimationDone() {
    if (this.fadeState === 'out') {
      this.hideBanner = true; // Remove from DOM
    }
  }

  getSubscriptionBranches() {
    this.branchesService.branchSubscriptionView().subscribe((res: any) => {
      this.subscriptionBranchesDetails = res?.data;
      if (this.subscriptionBranchesDetails?.branches?.length > 0) {
        this.branchNames = [];
        this.subscriptionEndDate = [];
        this.subscriptionBranchesDetails.branches.forEach((branch: any) => {
          this.branchNames.push(branch.name);
          this.subscriptionEndDate.push(branch.subscriptionDetails.subscriptionEndDate);
        });
      }

      // if (this.subscriptionBranchesDetails?.count > 0) {
      //   const bread = document.querySelector('.bread');
      //   if (bread) {
      //     bread.classList.add('height-150');
      //   }
      // }
    });
  }

  closeSubBranchesSection() {
    this.hideAlert = true;
    const header = document.querySelector('.header-section');
    if (header) {
      if (this.hideBanner) {
        header['style'].position = 'sticky';
        header['style'].top = '0';
        header['style'].left = '0';
        header['style'].width = '100%';
      } else {
        header['style'].position = 'sticky';
        header['style'].top = '50';
        header['style'].left = '0';
        header['style'].width = '100%';
      }
    }
  }

}